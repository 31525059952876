<template>
  <div class="menu-wrap">
    <!-- 国际课程 -->
    <div class="menu-item">
      <div class="menu-title" @click="onGo('/ic')">{{ $t('menu.international_curricula') }}</div>
      <div class="more-icon" :class="{t: showMore1}" @click="onToggleMore(1)"></div>
      <div class="menu-sub-warp" :class="{show3: showMore1}">
        <div class="menu-sub-item" @click="onGo('/apib')">AP/IB</div>
        <div class="menu-sub-item" @click="onGo('/igal')">IGCSE/A-Level</div>
        <div class="menu-sub-item" @click="onGo('/hkdse')">HKDSE</div>
      </div>
    </div>
    <!-- 竞赛学科 -->
    <div class="menu-item">
      <div class="menu-title" @click="onGo('/dc')">{{ $t('menu.discipline_competition') }}</div>
      <div class="more-icon" :class="{t: showMore2}" @click="onToggleMore(2)"></div>
      <div class="menu-sub-warp" :class="{show3: showMore2}">
        <div class="menu-sub-item" @click="onGo('/sat')">{{ $t('menu.Science_and_Technology') }}</div>
        <div class="menu-sub-item" @click="onGo('/aal')">{{ $t('menu.Arts_and_Languages') }}</div>
        <div class="menu-sub-item" @click="onGo('/sse')">{{ $t('menu.Social_Sciences_And_Economics') }}</div>
      </div>
    </div>
    <!-- 标化测试 -->
    <div class="menu-item">
      <div class="menu-title" @click="onGo('/st')">{{ $t('menu.standardized_tests') }}</div>
      <div class="more-icon" :class="{t: showMore3}" @click="onToggleMore(3)"></div>
      <div class="menu-sub-warp" :class="{show3: showMore3}">
        <div class="menu-sub-item" @click="onGo('/ieto')">IELTS_TOEFL</div>
        <div class="menu-sub-item" @click="onGo('/satact')">SAT_ACT</div>
        <div class="menu-sub-item" @click="onGo('/grgm')">GRE_GMAT</div>
      </div>
    </div>
    <!-- 资源中心 -->
    <div class="menu-item">
      <div class="menu-title" @click="onGo('/news')">{{ $t('menu.resource') }}</div>
      <div class="more-icon" :class="{t: showMore4}" @click="onToggleMore(4)"></div>
      <div class="menu-sub-warp" :class="{show2: showMore4}">
        <div class="menu-sub-item" @click="onGo('/news')">{{ $t('menu.News') }}</div>
        <div class="menu-sub-item" @click="onGo('/exam')">{{ $t('menu.simulation_test') }}</div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="menu-item">
      <div class="menu-title" @click="onGo('/aboutus')">{{ $t('menu.about_us') }}</div>
    </div>
    <!-- 联系我们 -->
    <div class="menu-item">
      <div class="menu-title" @click="onGo('/contactus')">{{ $t('menu.contact_us') }}</div>
      <div class="more-icon" :class="{t: showMore5}" @click="onToggleMore(5)"></div>
      <div class="menu-sub-warp" :class="{show3: showMore5}">
        <div class="menu-sub-item" @click="onGo('/contactus')">{{ $t('menu.Contact_Way') }}</div>
        <div class="menu-sub-item" @click="onGo('/consult')">{{ $t('menu.Online_Consult') }}</div>
        <div class="menu-sub-item" @click="onGo('/joinus')">{{ $t('menu.Join_Us') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'munu',
  data() {
    return {
      showMore1: true,
      showMore2: true,
      showMore3: true,
      showMore4: true,
      showMore5: true,
    }
  },
  computed: {
    ...mapGetters([
      'getUserName',
    ]),
  },
  methods: {
    onToggleMore(i) {
      this[`showMore${i}`] = !this[`showMore${i}`];
      console.log(111, this[`showMore${i}`]);
    },
    onGo(tag) {
      if (this.$route.path != tag) {
        this.$router.push(tag)
      }
      this.$emit('on-go', tag);
    }
  }
}
</script>

<style lang="less" scoped>
.menu-wrap {
  margin: 0px 20px;
  display: flex;
  align-items: center;
  .menu-item {
    position: relative;
    padding-right: 18px;
    margin-right: 15px;
    font-weight: 900;
    color: @fontColorMain;
    cursor: pointer;
    user-select: none;
    .more-icon {
      content: '';
      position: absolute;
      top: 80%;
      right: 7px;
      margin-top: -7.5px;
      border: solid @themeColor1;
      border-width: 0 1px 1px 0;
      padding: 2.5px;
      transition: all 0.2s ease 0s;
      transform: translateY(-50%) rotate(45deg);
      -webkit-transform: translateY(-50%) rotate(45deg);
    }
    .menu-sub-warp {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 1px 1px 30px rgba(0, 0, 0, .06);
      // border-top: 0px solid var(--global--color-secondary);
      min-width: 220px;
      width: 100%;
      background-color: #ffffff;
      display: block;
      opacity: 0;
      visibility: hidden;
      transition: all 0.4s ease 0s;
      z-index: 100000;
      border-radius: 8px;
      overflow: hidden;
      margin: 15px 0 0 0 !important;
      padding: 10px;
      .menu-sub-item {
        font-weight: 900;
        color: @fontColorMain;
        cursor: pointer;
        user-select: none;
        line-height: 2;
        &:hover {
          color: @themeColor1;
        }
      }
    }
    &.actived {
      color: @themeColor1;
    }
    &:hover {
      color: @themeColor1;
      .menu-sub-warp {
        opacity: 1 !important;
        visibility: visible !important;
      }
    }
  }
}

@media screen and (max-width: 980px) {
.menu-wrap {
  margin: 0px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .menu-item {
    width: 100%;
    padding-right: 30px;
    margin-right: 0;
    .menu-title {
      line-height: 2;
    }
    
    .more-icon {
      content: '';
      position: absolute;
      top: 14px;
      right: 7px;
      margin-top: -7.5px;
      border: solid @themeColor1;
      border-width: 0 2px 2px 0;
      padding: 5px;
      transition: all 0.2s ease 0s;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      &.t {
        transform-origin: 50% 50%;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);  
      }
    }
    .menu-sub-warp {
      position: relative;
      // transform: translateX(0);
      box-shadow: none;
      min-width: 220px;
      width: 100%;
      // background: transparent;
      padding: 0;
      padding-left: 24px;
      display: block;
      opacity: 1;
      visibility: visible;
      transition: all 0.4s ease 0s;
      z-index: 100000;
      border-radius: 8px;
      overflow: hidden;
      margin: 0 !important;
      height: 0;
      transition: height 0.3s ease 0s;;
      .menu-sub-item {
        font-weight: 900;
        color: @fontColorMain;
        cursor: pointer;
        user-select: none;
        line-height: 2;
        &:hover {
          color: @themeColor1;
        }
      }
      &.show2 {
        height: 60px;
      }
      &.show3 {
        height: 90px;
      }
    }
    &.actived {
      color: @themeColor1;
    }
    &:hover {
      color: @themeColor1;
    }
  }
}
}
</style>
