
/**
 * 图片转base64
*/
export const coverImageUrlToBase64 = (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = url + '?v=' + Math.random() // 处理缓存
    image.crossOrigin = '*' // 支持跨域图片
    image.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(image, 0, 0, image.width, image.height)
      const dataURL = canvas.toDataURL('image/jpeg')
      resolve(dataURL)
    }
    image.onerror = () => {
      resolve()
    }
  })
}

export const  formateDate = (oriDate) => {
	if (!oriDate) {
		return ''
	}
	const date = new Date(oriDate)
	const year =  date.getFullYear()
	const month =  date.getMonth() + 1
	const day = date.getDate()
	return `${year}-${month > 9 ? month : '0' + month}-${day > 9 ? day : '0' + day}`
}

export const getQueryParams = (search) => {
  search = search.substr(1); // 移除问号
  let params = {};
  search.split("&").forEach(pair => {
    if (pair) {
      let [key, value] = pair.split("=");
      params[decodeURIComponent(key)] = decodeURIComponent(value || "");
    }
  });
  return params;
}

export const add0 = (num) => {
  return num > 9 ? `${num}` : `0${num}`
}

// 防抖
export const debounce = (func, delay) => {
  let timer;
  return function(...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

/**
 * 下载文件
*/
export const documentDownload = (url) => {
  const link = document.createElement('a')
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}