import axios from 'axios'
import { Message } from 'element-ui'

import { getToken, removeToken } from './auth'

const service = axios.create({
  timeout: 15000
})

service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers.Authorization =`Bearer ${getToken()}`;
    }
    return config
  },
  error => {
    return {
      code: -1,
      msg: error.message
    }
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    // 返回码不等于0表示请求有异常
    if (res.code !== 0) {
      if (response.status == '200') {
        Message({
          message: res.msg || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
      }
    }
    return res
  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 3 * 1000
    })
    removeToken()
    return {
      code: -1,
      msg: error.message
    }
  }
)

export default service
