<template>
  <div class="us-nav-wrap">
    <div class="us-nav-inner" :class="{open: showMidMenu}">
      <div class="navbar-left">
        <div class="logo-wrao" @click="onGo('/home')">
          <img src="@/assets/logo.png">
          <div class="sys-title">{{ $t('home.system_name') }}</div>
        </div>
        <div class="fold-wrap" @click="toggleMidMenu">
          <div class="burgerbar top"></div>
          <div class="burgerbar middle"></div>
          <div class="burgerbar bottom"></div>                    
        </div>
      </div>
      <div class="navbar-right">
        <Menu @on-go="showMidMenu = false" />
        <div class="right-more-row">
          <div v-if="!getUserName" class="right-item" @click="onLogin">{{ $t('home.login') }}</div>
          <template v-else>
            <div class="right-item" @click="onGo('/account')">{{ getUserName }}</div>
            <el-tooltip class="item" effect="dark" :content="$t('home.logout')" placement="bottom">
              <div class="right-item" @click="onLoginOut">
                <i class="el-icon-close"></i>
              </div>
            </el-tooltip>
          </template>
          <div class="line"></div>
          <div class="lang-wrap">
            <div :class="{ actived: language === 'EN' }" class="lang-item" @click="onChangeLang('EN')">EN</div>
            <div :class="{ actived: language === 'ZH' }" class="lang-item" @click="onChangeLang('ZH')">中</div>
          </div>
        </div>
      </div>
    </div>
    <login-form ref="loginForm" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import LoginForm from '_c/login-form'
import Menu from './Menu'

export default {
  name: 'nav-bar',
  components: { LoginForm, Menu },
  data() {
    return {
      title: '炽道国际教育',
      showTheme: false,
      language: "ZH",
      showMidMenu: false,
    }
  },
  computed: {
    ...mapGetters([
      'getUserName',
    ]),
  },
  mounted() {
    const lang = localStorage.getItem("chidao_lan_locale") || "ZH";
    this.onChangeLang(lang)
  },
  methods: {
    ...mapActions({
      actionSetLang: 'setLang',
      actionLoginOut: 'userLogout'
    }),
    ...mapActions({
      setLang: 'SET_LANG'
    }),
    toggleMidMenu() {
      this.showMidMenu = !this.showMidMenu;
    },
    onChangeLang(lang) {
      localStorage.setItem("chidao_lan_locale", lang);
      this.$i18n.locale = lang;
      this.language = lang;
      this.actionSetLang(lang);
      this.showMidMenu = false;
    },
    onGo(tag) {
      if (this.$route.path != tag) {
        this.$router.push(tag)
      }
      this.showMidMenu = false;
    },
    onLogin() {
      this.$refs.loginForm.show()
      this.showMidMenu = false;
    },
    onLoginOut() {
      this.showMidMenu = false;
      this.$confirm('确定要退出当前账号吗?', '退出账号', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(async () => {
        const ret = await this.actionLoginOut()
        if (ret.code === 0) {
          // window.location.href = `/home`
          this.$router.push('/home')
        }
      }).catch(() => { })
    }
  }
}
</script>

<style lang="less" scoped>
@barHeight: 70px;
@pageWidth: 1600px;
@splitColor: #eee;

.us-nav-wrap {
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  top: 0;
  z-index: 999;
  color: @fontColorMain;
  font-size: 15px;
  height: @barHeight;
  overflow: visible;
  background: #fff;


  .us-nav-inner {
    width: auto;
    max-width: @pageWidth;
    // min-width: 1290px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: @barHeight;
    color: #333;

    .navbar-left {
      .logo-wrao {
        cursor: pointer;
        img {
          height: 40px;
        }
        .sys-title {
          font-weight: 600;
          color: #233568;
          margin-left: 4px;
          line-height: 1;
        }
      }
      .fold-wrap {
        width: 29px;
        height: 20px;
        display: none;
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        cursor: pointer;
        .burgerbar {
          width: 29px;
          height: 4px;
          border-radius: 10px;
          background-color: @themeColor1;
          margin: 0 0 4px 0;
          transition: all 0.2s ease 0s;
          &.top {
            transform-origin: left top;
          }
          &.middle {
            transform-origin: left top;
          }
          &.bottom {
            margin: 0;
            transform-origin: left bottom;
          }
        }
      }
    }

    .navbar-right {
      display: flex;
      align-items: center;
      height: 100%;
      .right-more-row {
        display: flex;
        align-items: center;
        .right-item {
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0 10px;
          font-size: 14px;
          cursor: pointer;

          &:hover {
            background-color: @splitColor;
          }
        }

        .line {
          height: 40px;
          width: 2px;
          margin: 0 10px;
          background-color: @themeColor1;
        }

        .lang-wrap {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .lang-item {
          color: @fontColorMain;
          cursor: pointer;
          user-select: none;

          &.actived {
            color: @themeColor1;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 980px) {
.us-nav-wrap {
  height: @barHeight;
  overflow: visible;
  .us-nav-inner {
    width: 100%;
    display: flex;
    flex-direction: column;

    .navbar-left {
      width: 100%;
      height: @barHeight;
      flex-shrink: 0;
      padding: 0 12px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo-wrao {
        display: flex;
        align-items: center;
      }
      .fold-wrap {
        display: block;
      }
    }

    .navbar-right {
      height: 0;
      width: 100%;
      display: none;
      flex-direction: column;
      background: #fff;
      padding: 12px;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, .2);

      .right-more-row {
        width: 100%;
        display: flex;
        align-items: center;
        .right-item {
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0 10px;
          font-size: 14px;
          cursor: pointer;

          &:hover {
            background-color: @splitColor;
          }
        }

        .line {
          height: 40px;
          width: 2px;
          margin: 0 10px;
          background-color: @themeColor1;
        }

        .lang-wrap {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .lang-item {
          color: @fontColorMain;
          cursor: pointer;
          user-select: none;

          &.actived {
            color: @themeColor1;
            font-weight: 600;
          }
        }
      }
    }
    
    &.open {
      .navbar-left {
        .fold-wrap {
          .burgerbar {
            &.top {
              transform: rotate(45deg) translate(3px, -5px);
              width: 29px;
              height: 4px;
            }
            &.middle {
              display: none;
            }
            &.bottom {
              transform: rotate(-45deg) translate(-3px, 11px);
              width: 29px;
              height: 4px;
            }
          }
        }
      }
      .navbar-right {
        height: auto;
        display: block;
      }
    }
  }
}
}
</style>
