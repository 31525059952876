import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import actions from './actions'

Vue.use(Vuex)

const state = {
  name: '',
  role: 0,
  userInfo: {
    id: '',
    name: '',
    phone: '',
    role: ''
  },
  allRoutes: [],
  accessRoutes: [
    { name: 'subject_cate' }
  ],
  // 业务数据
  subject_cate_list: [],
  subject_list: [],
  question_type_list: [
    { id: 1, name: '单选' },
    { id: 2, name: '简答' },
    { id: 3, name: '多选' },
  ],
  file_type_list: [],
  news_type_list: [
    { id: 1, name: '新闻' },
    { id: 2, name: '博客' },
    { id: 3, name: '活动' },
  ],
  // 当前语言
  lang: "ZH",
  // 当前视口宽度
  ViewportWidth: window.innerWidth,
}

const mutations = {
  SET_VW: (state, info) => {
    state.ViewportWidth = info
  },
  SET_LANG: (state, info) => {
    state.lang = info
  },
  SET_USER_INFO: (state, info) => {
    state.userInfo = info
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_ROLE: (state, role) => {
    state.role = role
  },
  CACHE_ROUTES: (state, routes) => {
    state.accessRoutes = routes
  },
  CACHE_ALL_ROUTES: (state, routes) => {
    state.allRoutes = routes
  },
  CACHE_PAGES: (state, pages) => {
    state.pages = pages
  },

  SET_SUBJECT_CATE: (state, data) => {
    state.subject_cate_list = data
  },
  SET_SUBJECT: (state, data) => {
    state.subject_list = data
  },
  SET_FILE_TYPE: (state, data) => {
    state.file_type_list = data
  },


}

const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})

export default store
