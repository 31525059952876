<template>
  <div class="app-wrapper">
    <navbar />
    <app-main/>
    <Footer />
  </div>
</template>

<script>
import { AppMain, Navbar, Footer  } from './components'
import { mapGetters } from 'vuex'

export default {
  name: 'UsLayout',
  components: {
    AppMain,
    Navbar,
    Footer
  },
  computed: {
    ...mapGetters([
      'getVW',
    ]),
  },
  watch: {
    getVW(newV, oldV) {
      console.log(newV, oldV);
    }
  }
}
</script>

<style lang="less" scoped>
  .app-wrapper {
    min-height: calc(~"100vh - 70px");
    width: 100%;
  }
</style>
