<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { debounce } from '@/utils/tools'
export default {
  name: 'App',
  mounted() {
    // console.log("app mounted");
    // this.vhResize();
    window.addEventListener('resize', this.vhResize);
  },
  methods: {
    ...mapActions({
      actionSetVW: 'setVW',
    }),
    vhResize: debounce(async function(e){
      const width = window.innerWidth;
      this.actionSetVW(width);
    }, 200)
  }
}
</script>
